define("discourse/plugins/discourse-uipath-mvp/discourse/components/mvp-user-preference/x-username", ["exports", "@ember/component", "discourse-common/helpers/i18n", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _i18n, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MvpUserPreferenceXUsername = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <div class="control-group">
      <label class="control-label">
        {{i18n "discourse_uipath_mvp.user_preferences.x_username.label"}}
      </label>
      <Input
        @type="text"
        @value={{@user.custom_fields.mvp_x_username}}
        class="input-xxlarge mvp-preferences__x-username"
      />
      <div class="instructions">
        {{i18n "discourse_uipath_mvp.user_preferences.x_username.instructions"}}
      </div>
    </div>
  
  */
  {
    "id": "frUkMRA5",
    "block": "[[[1,\"\\n  \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n    \"],[10,\"label\"],[14,0,\"control-label\"],[12],[1,\"\\n      \"],[1,[28,[32,0],[\"discourse_uipath_mvp.user_preferences.x_username.label\"],null]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[8,[32,1],[[24,0,\"input-xxlarge mvp-preferences__x-username\"]],[[\"@type\",\"@value\"],[\"text\",[30,1,[\"custom_fields\",\"mvp_x_username\"]]]],null],[1,\"\\n    \"],[10,0],[14,0,\"instructions\"],[12],[1,\"\\n      \"],[1,[28,[32,0],[\"discourse_uipath_mvp.user_preferences.x_username.instructions\"],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@user\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-uipath-mvp/discourse/components/mvp-user-preference/x-username.js",
    "scope": () => [_i18n.default, _component.Input],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "x-username:MvpUserPreferenceXUsername"));
  var _default = _exports.default = MvpUserPreferenceXUsername;
});