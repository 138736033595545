define("discourse/plugins/discourse-uipath-mvp/discourse/components/mvp-user-preference/location-details", ["exports", "@ember/component", "I18n", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _I18n, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MvpUserPreferenceLocationDetails = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    {{#if @user.can_change_location}}
      <div
        class="control-group mvp-pref-location"
        data-setting-name="user-location"
      >
        <label class="control-label" for="edit-location">{{I18n.t
            "user.location"
          }}</label>
        <div class="controls">
          <Input
            @type="text"
            @value={{@user.originalLocation}}
            class="input-xxlarge"
            id="edit-location"
          />
        </div>
        <div class="instructions">
          {{I18n.t "discourse_uipath_mvp.user_preferences.location.instructions"}}
        </div>
      </div>
    {{/if}}
  
  */
  {
    "id": "1or2NG40",
    "block": "[[[1,\"\\n\"],[41,[30,1,[\"can_change_location\"]],[[[1,\"    \"],[10,0],[14,0,\"control-group mvp-pref-location\"],[14,\"data-setting-name\",\"user-location\"],[12],[1,\"\\n      \"],[10,\"label\"],[14,0,\"control-label\"],[14,\"for\",\"edit-location\"],[12],[1,[28,[32,0,[\"t\"]],[\"user.location\"],null]],[13],[1,\"\\n      \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n        \"],[8,[32,1],[[24,0,\"input-xxlarge\"],[24,1,\"edit-location\"]],[[\"@type\",\"@value\"],[\"text\",[30,1,[\"originalLocation\"]]]],null],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"instructions\"],[12],[1,\"\\n        \"],[1,[28,[32,0,[\"t\"]],[\"discourse_uipath_mvp.user_preferences.location.instructions\"],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null]],[\"@user\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-uipath-mvp/discourse/components/mvp-user-preference/location-details.js",
    "scope": () => [_I18n.default, _component.Input],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "location-details:MvpUserPreferenceLocationDetails"));
  var _default = _exports.default = MvpUserPreferenceLocationDetails;
});