define("discourse/plugins/discourse-uipath-mvp/discourse/components/mvp-user-preference/languages", ["exports", "@ember/helper", "discourse-common/helpers/i18n", "select-kit/components/multi-select", "discourse/plugins/discourse-uipath-mvp/discourse/lib/language-options", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _helper, _i18n, _multiSelect, _languageOptions, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MvpUserPreferenceLanguages = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <div class="control-group">
      <label class="control-label">
        {{i18n "discourse_uipath_mvp.user_preferences.languages.label"}}
      </label>
      <MultiSelect
        @value={{@user.custom_fields.mvp_languages}}
        @content={{languageOptions}}
        @nameProperty="label"
        @valueProperty="value"
        @onChange={{fn (mut @user.custom_fields.mvp_languages)}}
        @options={{hash maximum=5}}
        class="input-xxlarge mvp-preferences__language"
      />
      <div class="instructions">
        {{i18n "discourse_uipath_mvp.user_preferences.languages.instructions"}}
      </div>
    </div>
  
  */
  {
    "id": "dWBHy9xc",
    "block": "[[[1,\"\\n  \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n    \"],[10,\"label\"],[14,0,\"control-label\"],[12],[1,\"\\n      \"],[1,[28,[32,0],[\"discourse_uipath_mvp.user_preferences.languages.label\"],null]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[8,[32,1],[[24,0,\"input-xxlarge mvp-preferences__language\"]],[[\"@value\",\"@content\",\"@nameProperty\",\"@valueProperty\",\"@onChange\",\"@options\"],[[30,1,[\"custom_fields\",\"mvp_languages\"]],[32,2],\"label\",\"value\",[28,[32,3],[[28,[31,0],[[30,1,[\"custom_fields\",\"mvp_languages\"]]],null]],null],[28,[32,4],null,[[\"maximum\"],[5]]]]],null],[1,\"\\n    \"],[10,0],[14,0,\"instructions\"],[12],[1,\"\\n      \"],[1,[28,[32,0],[\"discourse_uipath_mvp.user_preferences.languages.instructions\"],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@user\"],false,[\"mut\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-uipath-mvp/discourse/components/mvp-user-preference/languages.js",
    "scope": () => [_i18n.default, _multiSelect.default, _languageOptions.default, _helper.fn, _helper.hash],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "languages:MvpUserPreferenceLanguages"));
  var _default = _exports.default = MvpUserPreferenceLanguages;
});